import { Component, OnInit } from "@angular/core";
import { BRIDESMAIDS, GROOMSMEN } from "./wedding-party-data";

@Component({
  selector: "app-wedding-party",
  templateUrl: "./wedding-party.component.html",
  styleUrls: ["./wedding-party.component.scss"],
})
export class WeddingPartyComponent implements OnInit {
  groomsmen = GROOMSMEN;
  bridesmaids = BRIDESMAIDS;

  constructor() {}

  ngOnInit(): void {}
}
