import { Component, OnDestroy, OnInit } from "@angular/core";
import { FormGroup, FormBuilder, Validators } from "@angular/forms";
import { Observable, Subscription } from "rxjs";
import { Meal } from "../model/meal";
import { RSVP } from "../model/rsvp";
import { AngularFireDatabase } from "@angular/fire/database";
import { Couple } from "../model/couple";

@Component({
  selector: "app-rsvp",
  templateUrl: "./rsvp.component.html",
  styleUrls: ["./rsvp.component.scss"],
})
export class RSVPComponent implements OnInit, OnDestroy {
  plusOne: string;
  foodAllergies: string;

  couplesSub: Subscription;
  coupleMap: Map<string, Couple> = new Map();
  nameSearchQuery: string = "";
  selectedCouple: Couple;
  selectedCoupleKey: string;

  unrsvpSuccess = false;
  rsvpSuccess = false;

  searched = false;

  constructor(private db: AngularFireDatabase) {
    this.couplesSub = db
      .list<Couple>("couples")
      .snapshotChanges()
      .subscribe((couples) => {
        couples.forEach((couple, index, array) => {
          this.coupleMap.set(couple.key, couple.payload.val());
        });
        this.filterCouples();
        if (this.unrsvpSuccess) {
          this.selectedCouple = this.coupleMap.get(this.selectedCoupleKey);
          this.unrsvpSuccess = false;
        }
      });
  }

  ngOnInit() {}

  ngOnDestroy() {
    this.couplesSub.unsubscribe();
  }

  search(): void {
    this.searched = true;
    this.rsvpSuccess = false;
    this.filterCouples();
  }

  private filterCouples(): void {
    let numDisplayed = 0;
    this.coupleMap.forEach((couple, key, map) => {
      couple.displayed =
        this.nameSearchQuery !== "" &&
        (couple.guest1
          .toLowerCase()
          .includes(this.nameSearchQuery.toLowerCase()) ||
          couple.guest2
            .toLowerCase()
            .includes(this.nameSearchQuery.toLowerCase()));
      if (couple.displayed) {
        this.selectedCoupleKey = key;
        numDisplayed++;
      }
    });
    if (numDisplayed === 1) {
      this.selectedCouple = this.coupleMap.get(this.selectedCoupleKey);
      this.searched = false;
    }
  }

  selectCouple(couple: Couple, key: string): void {
    this.searched = false;
    this.selectedCouple = couple;
    this.selectedCoupleKey = key;
  }

  rsvp(status: boolean): void {
    const couplesRef = this.db.list("couples");
    couplesRef.update(this.selectedCoupleKey, {
      guest2: this.plusOne ?? this.selectedCouple.guest2,
      rsvp: status ? RSVP.Coming : RSVP.NotComing,
      foodAllergies: this.foodAllergies ?? "",
    });
    this.clear();
    this.rsvpSuccess = true;
  }

  unrsvp(): void {
    const CouplesRef = this.db.list("couples");
    CouplesRef.update(this.selectedCoupleKey, {
      rsvp: RSVP.Undecided,
    });
    this.unrsvpSuccess = true;
  }

  removePlusOne(): void {
    this.selectedCouple.guest2 = "";
  }

  private clear(): void {
    this.selectedCoupleKey = "";
    this.selectedCouple = null;
    this.nameSearchQuery = "";
  }
}
