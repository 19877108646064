<div class="full-height">
  <mat-toolbar>
    <mat-toolbar-row class="sticky">
      <div
        *ngFor="let route of routes"
        [routerLink]="route.path"
        routerLinkActive="active"
        class="nav-link"
        fxShow="true"
        fxHide.lt-md="true"
      >
        {{ route.display }}
      </div>

      <div class="width-full" fxShow="true" fxHide.gt-sm="true">
        <button class="menu-left" (click)="sidenav.toggle()"></button>
      </div>
    </mat-toolbar-row>
  </mat-toolbar>

  <mat-sidenav-container>
    <mat-sidenav #sidenav fxLayout="column">
      <div fxLayout="column">
        <div
          *ngFor="let route of routes"
          [routerLink]="route.path"
          routerLinkActive="active"
          class="nav-link small"
          (click)="sidenav.toggle()"
        >
          {{ route.display }}
        </div>
      </div>
    </mat-sidenav>
    <mat-sidenav-content>
      <router-outlet></router-outlet>
    </mat-sidenav-content>
  </mat-sidenav-container>
</div>
