import { NgModule } from "@angular/core";
import { Routes, RouterModule } from "@angular/router";
import { HomeComponent } from "./home/home.component";
import { PhotosComponent } from "./photos/photos.component";
import { RSVPComponent } from "./rsvp/rsvp.component";
import { RegistryComponent } from "./registry/registry.component";
import { WeddingPartyComponent } from "./wedding-party/wedding-party.component";

const routes: Routes = [
  {
    path: "home",
    component: HomeComponent,
  },
  {
    path: "wedding-party",
    component: WeddingPartyComponent,
  },
  {
    path: "registry",
    component: RegistryComponent,
  },
  {
    path: "photos",
    component: PhotosComponent,
  },
  {
    path: "rsvp",
    component: RSVPComponent,
  },
  {
    path: "**",
    redirectTo: "home",
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { useHash: true })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
