import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class HeaderComponent implements OnInit {
  routes = [
    {
      path: "home",
      display: "Home",
    },
    {
      path: "wedding-party",
      display: "Wedding Party",
    },
    {
      path: "registry",
      display: "Registry",
    },
    {
      path: "photos",
      display: "Photos",
    },
    {
      path: "rsvp",
      display: "RSVP",
    },
  ];

  constructor() {}

  ngOnInit(): void {}
}
