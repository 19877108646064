<img src="/assets/Amber-Austin-portrait.jpg" class="background-img" />
<div class="placeholder"></div>
<div class="container">
  <div class="search-field">
    <input
      class="search-input"
      type="text"
      [(ngModel)]="nameSearchQuery"
      placeholder="Enter your first and last name"
      (keyup.enter)="search()"
    />

    <button (click)="search()">Search</button>
  </div>
</div>

<div *ngIf="searched && !selectedCouple" class="container">
  <div class="card">Please search again, first and last name.</div>
  <!-- <div *ngFor="let couple of coupleMap | keyvalue">
    <div *ngIf="couple.value.displayed === true" class="card">
      <div class="label margin-bottom">
        {{ couple.value.guest1 }}
      </div>
      <div class="label margin-bottom">
        {{ couple.value.guest2 }}
      </div>
      <button (click)="selectCouple(couple.value, couple.key)">Select</button>
    </div>
  </div> -->
</div>

<div *ngIf="selectedCouple" class="container">
  <div class="card">
    <div *ngIf="selectedCouple.rsvp !== 'Undecided'" class="center">
      <div class="label margin-bottom">
        You've already RSVPed! Would you like to change your status?
      </div>
      <button (click)="unrsvp()">Change</button>
    </div>

    <div *ngIf="selectedCouple.rsvp === 'Undecided'">
      <div class="label margin-bottom">{{ selectedCouple.guest1 }}</div>
      <span *ngIf="selectedCouple.guest2 !== ''" class="flex">
        <div class="label margin-bottom">{{ selectedCouple.guest2 }}</div>
        <button class="delete" (click)="removePlusOne()">Remove</button>
      </span>
      <div *ngIf="selectedCouple.guest2 === ''" class="label margin-bottom">
        <div class="sub-label">Plus 1:</div>
        <input [(ngModel)]="plusOne" type="text" />
      </div>
      <div class="margin-bottom">
        <div class="label">Food allergies?</div>
        <textarea
          class="textarea"
          type="text"
          [(ngModel)]="foodAllergies"
        ></textarea>
      </div>
      <button class="margin-right" (click)="rsvp(true)">Coming</button>
      <button (click)="rsvp(false)">Not Coming</button>
    </div>
  </div>
</div>

<div class="container" *ngIf="rsvpSuccess">
  <div class="card">
    <span class="label">RSVP SUCCESS!</span>
  </div>
</div>
