import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-photos',
  templateUrl: './photos.component.html',
  styleUrls: ['./photos.component.scss']
})
export class PhotosComponent implements OnInit {

  numPhotos = 15;
  photos = [];

  constructor() { }

  ngOnInit(): void {
    for (let index = 1; index < this.numPhotos; index++) {
      this.photos.push(`/assets/amber-austin-${index}.jpg`);

    }
  }

}
