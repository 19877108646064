import { Person } from "../model/person";

export const GROOMSMEN: Person[] = [
  {
    name: "Christian Steele",
    title: "Best man",
    bio:
      "Christian and Austin were roomates in college. Christian is a master flutist, but hates performing in public. He has a vast bonsai tree collection, and loves listening to elevator music.",
  },
  {
    name: "Cole Wagner",
    title: "Groomsman",
    bio:
      "Austin met Cole at a bar crawl in Charlotte. Cole collects sunglasses and races lawnmowers. He place 13th in the semi-annual meatball making competition in Florida in 2001.",
  },

  {
    name: "Sean Fitzpatrick",
    title: "Groomsman",
    bio:
      "Sean is Austin's eldest half-brother. He is a secret agent who spends his free time shearing alpacas. Sean also has a love for sunsets, especially when accompanied by flaming hot cheetos.",
  },
  {
    name: "Alec Fitzpatrick",
    title: "Groomsman",
    bio:
      "Alec is the closest brother in age to Austin. He loves taking creepy bathroom selfies, and hanging out in between the swings and the see-saw after dark. Alec's hobbies include jogging, folding socks, and making paper airplanes.",
  },
  {
    name: "Caleb Fitzpatrick",
    title: "Groomsman",
    bio:
      "Caleb is Austin's youngest brother. Caleb never leaves the house because he is allergic to the sun. However, if you do find him outside, you're sure to have a great time on his scooter.",
  },
  {
    name: "Trevor Ventola",
    title: "Groomsman",
    bio:
      "Trevor is Amber's younger brother. He hates goats. His favorite food is kumquats mixed with refried beans. Trevor loves waking up before the crack of dawn and singing Aretha Franklin.",
  },
  {
    name: "Jasen McCaskey",
    title: "Groomsman",
    bio:
      "Jasen and Austin met through work. Jasen loves cycling, and usually rides 15 miles a day. On his ride, you'll be sure to catch him wearing cheetah print and closely followed by his trusty companion, a parrot named Tumbo.",
  },
  {
    name: "Zee Sneed",
    title: "Groomsman",
    bio:
      "Zee and Austin went to high school together. Zee is a potato chip taste tester, although he is blind in his right eye. He doesn't let this stop him from living life, as he loves performing circus stunts on his trampoline.",
  },
];

export const BRIDESMAIDS: Person[] = [
  {
    name: "Haley Ventola",
    title: "Maid of Honor",
    bio:
      "Haley is Amber's younger sister. She can speak 13 languages, and travels country to country performing her interpretive dance routine. She has an acute smell for spoiled milk and daffodils. ",
  },
  {
    name: "Cassie Perkins",
    title: "Matron of Honor",
    bio:
      "Cassie and Amber were childhood friends. Cassie is a master sculptor, specializing in hummingbird feeders. She is also the captain of the local bowling league, and can bowl a 300 without breaking a sweat.",
  },

  {
    name: "Sara Furlong",
    title: "Matron of Honor",
    bio:
      "Sara and Amber were roomates in college. She is a level 3 witch. When she's not cooking up potions and casting spells, Sara loves to relax by the pool, enjoying some candied sardines and an ice cold glass of chocolate milk.",
  },
  {
    name: "Kathleen Floyd",
    title: "Bridesmaid",
    bio:
      "Kathleen and Amber grew up together. Kathleen stole 3.5 quarts of liquor from a local saloon and is serving her time now. This is only her 4th offense though, as she is usually found at home knitting kitten mittens.",
  },
  {
    name: "Molly Wooton",
    title: "Bridesmaid",
    bio:
      "Molly and Amber met through work. Molly is arachibutyrophobic. This means she is afraid of peanut butter sticking to her mouth. For this reason, she sticks to grilled cheese, cut diagonally obviously.",
  },
  {
    name: "Taylor Melton",
    title: "Bridesmaid",
    bio:
      "Taylor and Amber met in college. She has enormous eyebrows, so she can only see when she pulls them back behind her eyes. She also holds the world record for longest jump rope while cooking ramen.",
  },
  {
    name: "Allison Hixon",
    title: "Bridesmaid",
    bio:
      "Allison and Amber met through Molly. Allison is a 3-time National Roller Derby bronze medalists. She also competes in baking shows and competitions. She makes a killer Baklava and Baumkuchen.",
  },
  {
    name: "Candace Fitzpatrick",
    title: "Bridesmaid",
    bio:
      "Candace is Austin's younger sister. She races pigs for a living. Her fastest swine can outrun most children. When Candace isn't racing, she loves taking long jogs while listening to her favorite podcast: Hammocks make the world go round.",
  },
];
