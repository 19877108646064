import { Component, OnInit } from '@angular/core';
import { differenceInDays, differenceInMonths } from 'date-fns';
import { UtilityService } from '../services/utility.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {

  endDate: Date;
  monthsDiff: number;
  daysDiff: number;

  isMobile: boolean;

  constructor(private util: UtilityService) { }

  ngOnInit(): void {
    this.endDate = new Date(2021, 4, 15, 0, 0, 0, 0);
    const today = new Date();
    this.monthsDiff = differenceInMonths(this.endDate, today);
    this.daysDiff = differenceInDays(this.endDate, today);

    this.isMobile = this.util.isMobile;
  }

}