// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  firebase: {
    apiKey: "AIzaSyBxGojUm4_nOJveoIGpEaafDC7yiUgapKs",
    authDomain: "fitzpatrick-wedding.firebaseapp.com",
    projectId: "fitzpatrick-wedding",
    databaseURL: "https://fitzpatrick-wedding-default-rtdb.firebaseio.com/",
    storageBucket: "fitzpatrick-wedding.appspot.com",
    messagingSenderId: "584170151030",
    appId: "1:584170151030:web:7f51cd1826fdba5e7a5357",
    measurementId: "G-EELJE22RT6",
  },
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
