<div class="container wood">
  <div class="container flex">
    <h1 class="title man">Meet the Groomsman</h1>
  </div>
  <div class="container flex">
    <div *ngFor="let groomsman of groomsmen; index as i" class="card">
      <img
        class="profile-image"
        [src]="'/assets/groomsman' + (i + 1) + '.jpg'"
      />
      <h1 class="name man">{{ groomsman.name }}</h1>
      <h2 class="subtitle">{{ groomsman.title }}</h2>
      <p class="bio">{{ groomsman.bio }}</p>
    </div>
  </div>
</div>

<div class="container flowers">
  <div class="container flex darken">
    <h1 class="large-title girl">Meet the Bridemaids</h1>
  </div>
  <div class="container flex darken">
    <div *ngFor="let bridesmaid of bridesmaids; index as i" class="card">
      <img
        class="profile-image"
        [src]="'/assets/bridesmaid' + (i + 1) + '.jpg'"
      />
      <h1 class="name girl">{{ bridesmaid.name }}</h1>
      <h2>{{ bridesmaid.title }}</h2>
      <p class="bio">{{ bridesmaid.bio }}</p>
    </div>
  </div>
</div>
